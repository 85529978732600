import //debugger from './//debugger';
import Preview from './Preview';
import Swatches from './Swatches';
import Palette from './Palette';

export {
  //debugger, Preview, Swatches, Palette
};

export default {
  '//debugger': //debugger,
  'preview': Preview,
  'swatches': Swatches,
  'palette': Palette
};
